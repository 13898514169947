/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkStyledText, LoadingIndicator, TabbedInterface, Text, TextInput, TEXT_STYLES } from '../components/flowComponents';
import { useGetSuggestedTagsForStats, useGetTaggedSessionAndUserStats } from '../fetch/endpoints';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import { CSVLink } from "react-csv";
import { EventCard } from '../Sessions/EventCard';

export const UsageReport = () => {
  const [tagFilter, setTagFilter] = useState('flow club')

  let { result: suggestedTags } = useAutomaticallyFetch(useGetSuggestedTagsForStats, {}, { transform: result => result.tags })
  if (suggestedTags === null) { suggestedTags = [] }

  const now = dayjs()
  const [dateRange, setDateRange] = useState({ startDate: now.startOf("week").format("YYYY/MM/DD"), endDate: now.startOf("week").add(1, 'week').format("YYYY/MM/DD") })

  const { result: sessionAndUserStats, fetching, error } = useAutomaticallyFetch(useGetTaggedSessionAndUserStats,
    {
      tag: tagFilter,
      dateInfo: dateRange
    }, {
      condition: tagFilter !== '',
      dependencies: [tagFilter, dateRange]
    }
  )
  const { users, events } = sessionAndUserStats ?? { users: [], events: [] }

  const loadingFinished = useFetchSucceeded(fetching, error)

  const tabs = [
    { title: "Users", Content: <UsersDataTable key='users' users={users} tagFilter={tagFilter} dateRange={dateRange} /> },
    { title: "Events", Content: <EventsDataTable key='events' events={events} /> },
  ]
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <div css={css`padding: 16px; display: flex; flex-direction: column; gap: 16px; min-height: 100vh;`}>
      <div css={css`display: flex; gap: 16px;`}>
        <TextInput wrapperCustomCss={css`width: 200px;`} label={'Tag Filter'} placeholder={'animalz'} value={tagFilter} onChange={event => setTagFilter(event.target.value)} />
        <TextInput value={dateRange.startDate} label={'Start Date'} onChange={(event) => setDateRange(dateRange => ({ ...dateRange, startDate: event.target.value }))} />
        <Text>–></Text>
        <TextInput value={dateRange.endDate} label={'End Date (excludes this date)'} onChange={(event) => setDateRange(dateRange => ({ ...dateRange, endDate: event.target.value }))} />
      </div>
      <div>
        <Text style={TEXT_STYLES.SUBTITLE_2}>Suggested tags (recent sessions):</Text>
        <div css={css`display: flex; flex-wrap: wrap; gap: 12px; max-width: 600px;`}>
          {suggestedTags.map(({ tag, recentSessions }) => <LinkStyledText onClick={() => setTagFilter(tag)}>{tag} ({recentSessions})</LinkStyledText>)}
        </div>
      </div>
      {fetching && (
        <div css={css`display: flex; justify-content: center;`}>
          <Text>Loading usage data...</Text>
          <LoadingIndicator />
        </div>
      )}
      {loadingFinished &&
        <TabbedInterface
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        />}
    </div>
  )
}

const UsersDataTable = ({ users, tagFilter, dateRange }) => {
  if (users.length === 0) {
    return (
      <div css={css`
        display: flex;
        justify-content: center;
        padding: 16px;
      `}>
        <Text style={TEXT_STYLES.APP_H5}>No participant data found for this month & this tag filter</Text>
      </div>
    )
  }

  const parsedUsersForCsvDownload = users.map(user => {
    const lastSession = user.participants[user.participants.length - 1]
    return {
      "Display Name": user.displayName.replaceAll('"', '""'),
      "Email": user.email,
      "Session Count": user.participants.length,
      "Last Attended Session": lastSession !== undefined ? dayjs(lastSession.start).format('ddd, MMM D h:mm a z') : 'None in period'
    }
  })

  return (
    <div css={css`display: flex; flex-direction: column; align-items: center; gap: 8px;`}>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Display Name</th>
            <th>Email</th>
            <th>Session Count</th>
            <th>Last Attended Session</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => {
            const lastSession = user.participants[user.participants.length - 1]
            return (
              <tr key={user.id}>
                <td>{user.displayName}</td>
                <td>{user.email}</td>
                <td>{user.participants.length}</td>
                <td><SessionLink lastSession={lastSession} /></td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <UsersSessions users={users} />
      <CSVLink filename={`Flow Club Usage Data – ${tagFilter} – ${dayjs(dateRange.startDate).format('MMMM DD, YYYY')}-${dayjs(dateRange.endDate).format('MMMM DD, YYYY')}`} data={parsedUsersForCsvDownload}>Download CSV</CSVLink>
    </div>
  )
}

const UsersSessions = ({ users }) => {
  return (
    <div css={css`display: flex; flex-direction: column; gap: 32px;`}>
      {users.map(user => (
        <div key={user.id} css={css`
          padding: 24px;
          border: 1px solid #eee;
          border-radius: 8px;
        `}>
          <div css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
          `}>
            <div>
              <Text style={TEXT_STYLES.APP_H2}>{user.displayName}</Text>
              <Text>{user.email}</Text>
              {user.weeklySpecificGoal &&
                <Text>{user.weeklyHoursGoal} hours towards {user.weeklySpecificGoal}</Text>
              }
            </div>
          </div>

          <div css={css`margin-bottom: 24px;`}>
            <Text style={TEXT_STYLES.APP_H3}>Completed Sessions</Text>
            <div css={css`
              display: flex;
              flex-wrap: wrap;
              margin-top: 12px;
            `}>
              {user.participants && user.participants.filter(participant => participant && participant.title !== undefined).map((participant, index) => (
                <EventCard
                  key={participant.eventId}
                  isFirst={index === 0}
                  isLast={index === user.participants.length - 1}
                  showDate={true}
                  event={participant}
                  sessionIsInPast={true}
                  dontLinkCard={true}
                  hideButton={true}
                  showSessionParticipant={true}
                />
              ))}
            </div>
          </div>

          <div>
            <Text style={TEXT_STYLES.APP_H3}>Upcoming Sessions</Text>
            <div css={css`
              display: flex;
              flex-wrap: wrap;
              margin-top: 12px;
            `}>
              {user.upcomingSessions && user.upcomingSessions.map((participant, index) => (
                <EventCard
                  key={participant.eventId}
                  showDate={true}
                  isFirst={index === 0}
                  isLast={index === user.participants.length - 1}
                  event={participant}
                  hideButton={true}
                  sessionIsPast={false}
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const SessionLink = ({ lastSession }) => {
  return (
    lastSession !== undefined ? <Link to={`/session/${lastSession.eventId}`}>{dayjs(lastSession.start).format('ddd, MMM D h:mm a z')}</Link> : 'None in period'
  )
}

const EventsDataTable = ({ events }) => {
  if (events.length === 0) {
    return (
      <div css={css`
        display: flex;
        justify-content: center;
        padding: 16px;
      `}>
        <Text style={TEXT_STYLES.APP_H5}>No events found for this month & this tag filter</Text>
      </div>
    )
  }

  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>Title</th>
          <th>Host</th>
          <th>Start</th>
          <th>Status</th>
          <th># Booked</th>
          <th># Joined</th>
          <th>Users Booked</th>
        </tr>
      </thead>
      <tbody>
        {events.map(event => {
          return (
            <tr key={event.id}>
              <td><Link to={`/session/${event.id}`}>{event.data.title}</Link></td>
              <td>{event.data.host}</td>
              <td css={css`font-family: monospace;`}>{dayjs.unix(event.data.start._seconds).format('MM/DD/YYYY (ddd), hh:mm a z')}</td>
              <td>{event.data.status}</td>
              <td>{event.participants.length}</td>
              <td>{event.participants.filter(participant => participant.data.joined !== null && participant.data.joined !== undefined).length}</td>
              <td>{event.participants.map(participant => <ParticipantName participant={participant} />)}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

const ParticipantName = ({ participant }) => {
  return (
    <Text customCss={css`
      ${participant.wasFirstSession && css`font-weight: bold;`}
      ${(participant.data.joined === null || participant.data.joined === undefined) && css`font-style: italic;`}
    `}>
      {participant.displayName}
    </Text>
  )
}